import request from '../utils/request';
// 提问推荐
export const recommend = (question) => {
  return request({
    url: "/api/blade-qa/textcorpus/front/recommend",
    method: "get",
    params:{
      question
    }
  });
};

export const IKList = (title) => {
  return request({
    url: "/api/blade-asks/wzasksevent/front/IK-list",
    method: "get",
    params:{
      title,
      pageSize:10
    }
  });
};


//获取最新政策列表
export const getCorpusList = (current, size,params) => request({
  url: '/api/blade-qa/textcorpus/front/list',
  method: 'get',
  showLoading:true,
  params: {
    ...params,
    current, 
    size,
  },
});

//获取政策详情
export const getCorpusDetail= (id) => request({
  url: '/api/blade-qa/textcorpus/front/detail',
  method: 'get',
  params: {
    id
  },
});