<template>
  <div class="policy">
    <div class="head">
      <ul class="tabs">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ current: item.value == current }"
          @click="onItemClick(item)"
        >
          {{ item.key }}
          <el-divider
            v-if="index !== tabs.length - 1"
            direction="vertical"
          ></el-divider>
        </li>
      </ul>
    </div>
    <ul class="list">
      <li
       @click="onDetail(item)" v-for="item in corpusList" :key="item.id"
      >
        <div class="content">
          <div class="title">
            <div class="tag">问</div>
            <span class="txt">{{item.question}}</span>
          </div>
          <div class="desc">
            {{item.answer}}
          </div>
          <div class="next">
            <div class="lt">
              <span>{{item.deptName}}</span>
            </div>
            <div class="rt">{{item.updateTime}}</div>
          </div>
        </div>
      </li>
    </ul>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getCorpusList } from "@/api/textcorpus";
export default {
  name: "policy",
  data() {
    return {
      current: 1,
      tabs: [
        {
          key: "时间正序",
          value: 1,
        },
        {
          key: "时间倒序",
          value: 2,
        },
      ],
      currentPage: 1,
      currentSize: 10,
      total: 0,
      corpusList: [],
    };
  },
  activated(){
    console.log('activated')
  },
  deactivated(){},
  mounted() {
    this.getCorpusList();
  },
  methods: {
    onDetail(item){
      this.$router.push("/web/zcdetail/" + item.id);
    },
    async getCorpusList() {
      const params = {};
      if (this.current == 1) {
        params.ascs = "update_time";
      } else {
        params.descs = "update_time";
      }
      const res = await getCorpusList(this.currentPage, this.currentSize, {
        ...params,
      });
      // console.log(res);
      this.corpusList = res.data.records;
      this.total = res.data.total;
    },
    onItemClick(item) {
      this.current = item.value;
      this.currentPage = 1;
      this.getCorpusList();
    },
    handleSizeChange(val) {
      this.currentSize = val;
      this.getCorpusList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCorpusList();
    },
  },
};
</script>

<style lang="scss" scoped>
.policy {
  background: #ffffff;
  .head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 68px;
    background: url("~images/policy/head-bg.png") top left no-repeat;
    background-size: 810px 68px;
    .tabs {
      display: flex;
      margin-right: 20px;
      li {
        font-size: 16px;
        color: #90949e;
        cursor: pointer;
        &.current {
          font-weight: 700;
          color: #2a2b2e;
        }
      }
    }
  }
  .list {
    li {
      box-shadow: 0px -1px 0px 0px #f0f2f5 inset;
      position: relative;
      cursor: pointer;
      .content {
        padding: 20px;
        .title {
          display: flex;
          align-items: center;
          .tag {
            font-size: 13px;
            color: #5cbd31;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #5cbd31;
          }
          .txt {
            font-size: 18px;
            color: #333333;
            font-weight: 700;
            margin-left: 8px;
          }
        }
        .desc {
          font-size: 14px;
          color: #999999;
          line-height: 26px;
          margin-top: 14px;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .next {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
          .lt {
            font-size: 14px;
            color: #52545b;
          }
          .rt {
            font-size: 13px;
            color: #999999;
          }
        }
      }
      .pos__icon {
        width: 68px;
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
  .el-pagination {
    padding: 20px;
  }
}
</style>